import { useMutation, useQuery } from '@apollo/client';
import {
  GetCraftsWithUsersDocument,
  CreateFlightDocument,
  IntervalType,
  FlightCreateWithIntervalsInput,
  GetFlightPurposesDocument,
  GetFlightsDocument,
  LogType,
  GetCraftDocument,
  IntervalSubtype,
  GetOrganizationFlightBillingDocument,
  GetReportInfoDocument,
  GetTransactionsDocument,
  CraftType,
  GetIntervalsDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import { FormLabel, FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import DatePicker from 'components/DatePicker/DatePicker';
import Button from 'components/Button/Button';
import RouteItem, { Route } from '../Components/RouteItem';
import IntervalItem from '../Components/IntervalItem';
import FilePicker from 'components/FilePicker/FilePicker';
import { formatApiDateTimeUTC, formatApiDateUTC, formatToFixedNumber, formatUSD } from 'utils/formatter';
import { webFormat } from 'utils/statuses';
import PassengerItem, { Passenger } from '../Components/PassengerItem';
import { OrgSubtypes } from 'utils/orgTypes';
import PilotSelect from '../Components/PilotSelect';

export function getCurrentUTCTime() {
  const now = new Date();
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}:00`;
}
const intervalSchema = Yup.array()
.of(
  Yup.object().shape({
    startValue: Yup.number()
      .when("optional", {
        is: false, // When optional is false
        then: Yup.number()
          .required("Required")
          .typeError("Must be a valid number"),
        otherwise: Yup.number().typeError("Must be a valid number"),
      }),
    endValue: Yup.number()
      .when("optional", {
        is: false, // When optional is false
        then: Yup.number()
          .required("Required")
          .typeError("Must be a valid number"),
        otherwise: Yup.number().typeError("Must be a valid number"),
      }),
  })
)
.required("Intervals are required");

export const FlightSchema = Yup.object().shape({
  flightDate: Yup.date().required("Required"),
  fuelAddedBefore: Yup.number().typeError('Value must be a valid number').nullable(),
  fuelAddedAfter: Yup.number().typeError('Value must be a valid number').nullable(),
  oilAdded: Yup.number().typeError('Value must be a valid number').nullable(),
  intervals: intervalSchema,
  consumables: Yup.array().of(Yup.object().shape({
    startValue: Yup.number().nullable().typeError('Value must be a valid number').optional(),
    endValue: Yup.number().nullable().typeError('Value must be a valid number').optional(),
  })),
});


const New = function ({ closeFlyout, icaos }: { closeFlyout: () => void ; icaos?: Route[] }) {
  const { showToast, toastProps } = useToast();
  const { organizationId, craftId, organizationSubtype, user } = useSession();
  const [createFlight] = useMutation(CreateFlightDocument, {
    refetchQueries: [
      GetFlightsDocument,
      GetTransactionsDocument,
      { query: GetFlightPurposesDocument, variables: { where: { organizationId: { equals: organizationId } } } },
      { query: GetCraftDocument, variables: { craftId: craftId ?? '' } },
      { query: GetReportInfoDocument, variables: { id: organizationId } },
      { query: GetCraftsWithUsersDocument, variables: { organizationId } },
      { query: GetIntervalsDocument, variables: { craftId: craftId ?? '' } },
    ],
  });
  const { data: { crafts } = { craft: undefined } } = useQuery(GetCraftsWithUsersDocument, {
    variables: { organizationId },
    pollInterval: 0
  });
  const { data: { organization } = {} } = useQuery(GetOrganizationFlightBillingDocument, { variables: { id: organizationId }, pollInterval: 0 });
  const { data: { flightPurposes } = {} } = useQuery(GetFlightPurposesDocument, { variables: { where: { organizationId: { equals: organizationId } } }, pollInterval: 0 });
  const [currentCraft, setCurrentCraft] = useState(crafts?.find((craft) => craft.id === craftId) ?? crafts?.[0]);
  const [files, setFiles] = useState<File[]>([]);  
  const [route, setRoute] = useState<Route[]>( icaos ??  [{ icao:'' }, { icao:'' }]);
  const [passengers, setPassengers] = useState<Passenger[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({
    flightDate: new Date(),
    purposeId: flightPurposes?.[0]?.id ?? '',
    customPurpose: '',
    craft: currentCraft?.id,
    pilot: '',
    intervals:  currentCraft?.normalLoggingIntervals.map(
      ({ __typename, ...interval }) => ({ 
        ...interval, 
        startValue: interval?.recentIntervalLog?.endValue, endValue: null })
    ),
    consumables: currentCraft?.consumableIntervals?.map((interval) => ({ name: interval?.name, startValue: interval?.recentIntervalLog?.endValue ?? undefined, endValue: undefined, id: interval?.id, intervalSubType: interval.intervalSubType })),
    notes: '',
    fuelAddedBefore: '',
    fuelAddedAfter: '',
    oilAdded: '',
    billable: false,
    proRataShare: false,
  });
  useEffect(() => {
    if(currentCraft && flightPurposes){
      setInitialValues(
        {
          ...initialValues,
          craft: currentCraft?.id,
          billable: flightPurposes?.[0].name !== "Maintenance" ? organization?.flightBillingEnabled : false,
          pilot: currentCraft?.userProfiles?.find( (tempProfile) => tempProfile?.id === user?.userOrganizationProfileId )?.id ?? currentCraft?.userProfiles?.[0]?.id,
          intervals:  currentCraft?.normalLoggingIntervals.map(
            ({ __typename, ...interval }) => ({ 
                              ...interval,
                              startValue: interval?.recentIntervalLog?.endValue,
                              endValue: '' })
          ),
          consumables: currentCraft?.consumableIntervals?.map((interval) => {
            if(interval.intervalSubType === IntervalSubtype.Fuel) return({ name: interval?.name, startValue: currentCraft?.fuelAmount !== 'Unknown' ? currentCraft?.fuelAmount : 0, endValue: undefined, id: interval?.id, intervalSubType: interval?.intervalSubType });
            return({ name: interval?.name, startValue: interval?.recentIntervalLog?.endValue, endValue: undefined, id: interval?.id, intervalSubType: interval?.intervalSubType })
          }),
        }
      );
      if(route[0]?.icao === '' && route[1]?.icao === '' && currentCraft?.lastAirport){
        setRoute([{ icao: currentCraft?.lastAirport?.icao, id: currentCraft?.lastAirport?.id }, { icao: '' }]);
      }
    }
    if(flightPurposes){
      setInitialValues((preVal) => ({
        ...preVal,
        purposeId: flightPurposes?.find((purpose) => purpose.sort === 0)?.id ?? flightPurposes?.[0]?.id,
      }));
    }
  }, [currentCraft, flightPurposes]); 
  let billingInterval = currentCraft?.loggingOption === LogType.Loghobbs ? currentCraft?.normalLoggingIntervals.find( (interval) => interval.intervalSubType === IntervalSubtype.Hobbs ) : currentCraft?.normalLoggingIntervals.find( (interval) => interval.intervalSubType === IntervalSubtype.Tach );
  if(currentCraft?.loggingOption === LogType.Logboth ) {
    billingInterval = currentCraft?.hourlyRateMethod === LogType.Loghobbs ? currentCraft?.normalLoggingIntervals.find( (interval) => interval.intervalSubType === IntervalSubtype.Hobbs ) : currentCraft?.normalLoggingIntervals.find( (interval) => interval.intervalSubType === IntervalSubtype.Tach );
  }

  function updateList<T>(
    list: T[],
    setList: React.Dispatch<React.SetStateAction<T[]>>,
    index: number,
    type: string,
    value: string
  ) {
    const newList = [...list];
    newList[index][type] = value;
    setList(newList);
  }
  
  function addToList<T>(
    list: T[],
    setList: React.Dispatch<React.SetStateAction<T[]>>,
    newItem: T
  ) {
    setList([...list, newItem]);
  }
  
  function removeFromList<T>(
    list: T[],
    setList: React.Dispatch<React.SetStateAction<T[]>>,
    index: number
  ) {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  }
  
  useEffect(() => {
    if (crafts){
      setCurrentCraft(crafts.find((craft) => craft.id === craftId) ?? crafts[0]);
    }
  }, [crafts, craftId]);

  const handleSubmit = async (
    { ...values }: typeof initialValues,
    resetForm: (nextState?: Partial<any> | undefined) => void
  ) => {
    setIsSubmitting(true);
    //Check Passengers
    if(passengers?.length > 0 && passengers?.find((passenger) => !passenger.id)){
      showToast({ type: ToastLength.Normal, title: 'Invaild Passenger(s)' });
      setIsSubmitting(false);
      return;
    }
    //Check Routes
    for(let i = 0; i < route.length; i++) {
      if (route[i].icao === '') {
        showToast({ type: ToastLength.Normal, title: 'Airport fields can not be left empty.' });
        setIsSubmitting(false);
        return;
      }
    }
    
    const intervals = [ ...values.intervals.map((interval) => ( { id: interval.id, intervalSubType: interval.intervalSubType, name: interval.name, startValue: formatToFixedNumber(interval.startValue), endValue: formatToFixedNumber(interval.endValue), intervalType: IntervalType.Normal } ) ),
       ...values?.consumables.map((interval) => ( { ...interval, startValue: formatToFixedNumber(interval?.startValue), endValue: formatToFixedNumber(interval?.endValue), intervalType: IntervalType.Consumable } ) ) ];
    const purposeId = values.purposeId;
    const fuelAddedBefore = Number(values.fuelAddedBefore);
    const fuelAddedAfter = Number(values.fuelAddedAfter);
    const oilAdded = Number(values.oilAdded);
    const pilotId = values.pilot;
    

    delete values.intervals; delete values.consumables; delete values.pilot; delete values.purposeId; delete values.fuelAddedBefore; delete values.fuelAddedAfter; delete values.oilAdded;
    const flightInput: FlightCreateWithIntervalsInput = {
      ...values,      
      flightDate: formatApiDateUTC(values.flightDate, true),
      ...( route.filter(airport => airport.id).length > 0 && { route: { connect: route.filter((airport) => airport?.id).map((airport) => ({ id: airport.id })) } }),
      icaos: route.map((airport) => airport.icao),
      craft: { connect: { id: currentCraft.id }, },
      userProfile: { connect: { id: pilotId } },
      intervalsForFlight: intervals,
      proRataShare: passengers.length ? values.proRataShare : false,
      ...(flightPurposes?.find((p) => values?.purposeId === p.id )?.name === 'Maintenance' && { billable: false } ),
      ...( passengers ? {passengers: { connect:  passengers.map((passenger) => ({ id: passenger?.id }))}} : { passengers : undefined }),
      consumableEntries: { create: [{ name: 'Fuel Added Before', value: fuelAddedBefore, userOrgProfile: { connect: { id: pilotId } } },
                                    { name: 'Fuel Added After', value: fuelAddedAfter, userOrgProfile: { connect: { id: pilotId } } },
                                    { name: 'Oil Added', value: oilAdded, userOrgProfile: { connect: { id: pilotId } } }] },
      ...(purposeId && purposeId !== 'Other' && { purpose:{ connect: { id: purposeId } } } ) ,
    };
    createFlight({ variables: { input: flightInput } }).then(
      async ( { data : { createFlightWithIntervals } = {} } ) => {
        if (files) {
          const formData = new FormData();
          files.forEach((file) => {
            formData.append('files', file);
          });
          formData.append('flightId', createFlightWithIntervals?.id);
          const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
        }
        setIsSubmitting(false);
        closeFlyout();
        resetForm();
      }
    ).catch((error) => {
      console.error(error);
      showToast({ type: ToastLength.Normal, title: 'Error Recording Flight' });
      setIsSubmitting(false);
    });
  };
  
  return (
    <>
      <Toast {...toastProps} />
      <Formik enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={FlightSchema}
        validateOnMount={true}
        onSubmit={()=>{}}>
        {({ values, setFieldValue, errors, setSubmitting, resetForm, isValid }) => {
          const totalBillableInterval = +Math.max(+(values?.intervals?.find( (inter) => inter?.id === billingInterval?.id )?.endValue ?? 0) - (values?.intervals?.find( (inter) => inter?.id === billingInterval?.id )?.startValue ?? 0), 0).toFixed(2);
          const totalBillable = +(values.proRataShare ? (currentCraft?.hourlyRate * totalBillableInterval) / (passengers.length + 1) : currentCraft?.hourlyRate * totalBillableInterval).toFixed(2);
          const fuelIndex = values?.consumables?.findIndex((item) => item.intervalSubType === IntervalSubtype.Fuel);
          const oilIndex = currentCraft?.type === CraftType.Multiengine ? values?.consumables?.findIndex((item) => item.name === "Right Engine Oil") : values?.consumables?.findIndex((item) => item.intervalSubType === IntervalSubtype.Oil);
          const oil2Index = currentCraft?.type === CraftType.Multiengine ? values?.consumables?.findIndex((item) => item.name === "Left Engine Oil") : undefined;
          const isMaintenance = flightPurposes?.find((p) => values?.purposeId === p.id )?.name === 'Maintenance';
          const pilotName = currentCraft?.userProfiles?.find( (tempProfile) => tempProfile?.id === values?.pilot )?.userName;
          return (
            <Form>
              <div className="flex flex-col p-4 h-full">
                <div className="border-t border-gray-200">
                  <Card title='General'>
                    <div className="flex w-full justify-between md:flex-row flex-col gap-3 mb-3">
                      <div className='flex flex-col w-full'>
                        <FullFormikSelect
                        name="craft"
                        label="Craft *"
                        onChange={(e) => {
                          setCurrentCraft(crafts?.find((c) => c.id === e.target.value));
                          setFieldValue('craft', e.target.value);
                        }}>
                          {crafts?.map((craftObj) => (
                            <option key={craftObj.id} value={craftObj.id}>
                              {craftObj.label}
                            </option>
                          ))}
                        </FullFormikSelect>
                      </div>
                      <div className='flex flex-col w-full'>
                        <FormLabel>Pilot *</FormLabel>
                        <PilotSelect
                          pilot={
                            {
                              name: pilotName ?? '',
                              id: values?.pilot,
                            }
                          }
                          setValue={(value)=>setFieldValue('pilot', value)}
                        />
                      </div>
                    </div>
                    <div className="flex w-full justify-between md:flex-row flex-col gap-3">
                      <div className='flex w-full'>
                        <FullFormikSelect name="purposeId" onChange={(event)=>{
                          setFieldValue('purposeId', event.target.value);
                          if(flightPurposes.find((purpose) => purpose.id === event.target.value)?.name === 'Maintenance' && values.billable){
                            setFieldValue('billable', false);
                          }else if(!values.billable){
                            setFieldValue('billable', true);
                          }
                        }} label="Purpose">
                          {[...flightPurposes ?? []]?.sort((a, b) => {
                            if (a.sort == null) return 1;
                            if (b.sort == null) return -1;
                            return a.sort - b.sort;
                          })?.map((purpose) => (
                            <option key={purpose.id} value={purpose.id}>
                              {purpose.name}
                            </option>
                          ))}
                          <option key="other" value="Other">
                            Other
                          </option>
                        </FullFormikSelect>
                      </div>
                      <div className='flex flex-col w-full'>
                        <FormLabel>Flight Date*</FormLabel>
                        <Field as={DatePicker} className="w-full" name="flightDate" />
                      </div>
                    </div>
                    { values.purposeId === 'Other' && <div className="flex w-full mt-3">
                      <FullFormikInput name="customPurpose" label="Custom Purpose" />
                    </div> }
                  </Card>
                  <Card title='Passengers' subtitle={`Add additional users to this flight${organizationSubtype === OrgSubtypes.FREE ? " with Coflyt Premium." : "."}`}>
                    <div className="flex w-full gap-9 justify-between">
                      <div className='flex flex-col w-full gap-2'>
                        {organizationSubtype === 'FREE' && <Button text="Upgrade to Coflyt Premium" size="xs" color="pale" className='mt-4' onClick={() => window.location.assign('/account/settings?settingsTab=Billing')} />}
                        {passengers.map((profile, idx) => (
                            <PassengerItem key={idx} temp={ true } index={idx} 
                            options={currentCraft?.userProfiles?.filter((userProfile) => 
                              !passengers.find((value) => value.id === userProfile.id) && values.pilot !== userProfile.id
                            ).map((userProfile) => ({ id: userProfile.id, name: userProfile?.user?.lastName ? userProfile?.user?.firstName + ' ' + userProfile?.user?.lastName : userProfile?.nameInvite }))} 
                            passenger={profile} 
                            handleItemUpdate={(ix, type, value) => updateList(passengers, setPassengers, ix, type, value)} 
                            handleRemoveItem={(ix) => removeFromList(passengers, setPassengers, ix)} />
                        ))}
                      </div>
                    </div>
                    <div className={`flex w-full items-center ${passengers.length ? 'justify-between' : 'justify-end'}`}>
                      {passengers.length > 0 && <FullFormikCheckBox name="proRataShare" label='Enable Pro Rata Share' isVertical={true} />}
                      {(passengers.length >= currentCraft?.userProfiles?.length || currentCraft?.userProfiles?.length > 1) && 
                        <Button onClick={() => addToList(passengers, setPassengers, { name: '', id: '' })} size='xs' color='navy' className='' text="Add Passenger" />
                      }
                    </div>
                  </Card>
                  <Card title='Route'>
                    <div className="flex w-full justify-between gap-2 mb-8">
                      <div className='flex flex-col w-full gap-2'>
                        {route.map((airport, idx) => (
                            <RouteItem key={idx} temp={ idx > 1 } index={idx} route={airport} 
                            handleItemUpdate={(ix, type, value) => updateList(route, setRoute, ix, type, value)} 
                            handleRemoveItem={(ix) => removeFromList(route, setRoute, ix)} />
                        ))}
                      </div>
                    </div>
                    <div className="flex w-full justify-end">
                      <Button onClick={() => addToList(route, setRoute, { icao:'' })} size='xs' color='navy' className='' text="Add Airport" />
                    </div>
                  </Card>
                  <Card title='Intervals'>
                    <div className='flex mb-4 font-semibold'>
                      <span className='w-1/3'>Name:</span>
                      <span className='w-1/3'>Out:</span>
                      <span className='w-1/3'>In:</span>
                    </div>
                    { values?.intervals?.map((interval, index) => (
                      <IntervalItem required={!interval.optional} key={index} interval={interval} error={errors?.intervals?.[index]} index={index} handleItemUpdate={(id, type, value) => {
                        //Find Idx of interval
                        const idx = values.intervals.findIndex((inter) => inter.id === id);
                        const newIntervals = [...values.intervals];
                        newIntervals[idx][type] = value;
                        setFieldValue('intervals', newIntervals);                      
                      }} />
                    )) }
                  </Card>
                  <Card title='Consumables'>
                    { organizationSubtype === OrgSubtypes.FREE ? 
                      <>
                        <span className='text-lg font-semibold text-slate-500'>Consumables are not available for free organizations</span>
                      </>
                    : 
                      <>{ values?.consumables && <>
                        <div className='flex mb-4 font-semibold'> 
                          <span className='w-1/3'>&nbsp;</span>
                          <span className='w-1/3'>Fuel Out:</span>
                          <span className='w-1/3'>Fuel In:</span>
                        </div>
                        <IntervalItem interval={{...values?.consumables[fuelIndex], name: 'Fuel' }} error={errors?.consumables?.[fuelIndex]} index={fuelIndex} handleItemUpdate={(id, type, value) => {
                            const newIntervals = [...values?.consumables];
                            newIntervals[fuelIndex][type] = value;
                            setFieldValue('consumables', newIntervals);                      
                        }} />
                        <div className='flex mb-4 font-semibold text-sm'> 
                          <span className='w-1/3 ml-1'>&nbsp;</span>
                          <span className='w-1/3 ml-1'>Fuel Added Before:</span>
                          <span className='w-1/3 ml-1'>Fuel Added After:</span>
                        </div>
                        <div className='flex mb-8 text-sm'>
                          <span className='w-1/3 self-center'>Fuel Added</span>
                           <FullFormikInput name="fuelAddedBefore" onChange={(e) => { 
                            setFieldValue('fuelAddedBefore', e.target.value);
                            //Add to fuel out
                            const newIntervals = [...values?.consumables];
                            newIntervals[fuelIndex].startValue = (Number( currentCraft?.fuelAmount === "Unknown" ? 0 : currentCraft?.fuelAmount )) + Number(e.target.value);
                            setFieldValue('consumables', newIntervals);         

                          }} className="w-1/3 pr-4 h-12" />
                          <FullFormikInput name="fuelAddedAfter" onChange={(e) => { 
                            setFieldValue('fuelAddedAfter', e.target.value);
                          }} className="w-1/3 pr-4 h-12" />
                        </div>
                        <hr className='my-4 border-y-[2px] border-rounded border-slate-100'/>
                        <div className='flex mb-4 font-semibold'> 
                          <span className='w-1/3 ml-4'>&nbsp;</span>
                          <span className='w-1/3 ml-4'>Oil Out:</span>
                          <span className='w-1/3 ml-4'>Oil In:</span>
                        </div>
                        <IntervalItem interval={{...values?.consumables[oilIndex], name: (oil2Index? "Right Oil" : "Oil") }} error={errors?.consumables?.[oilIndex]} index={oilIndex} handleItemUpdate={(id, type, value) => {                          
                            const newIntervals = values?.consumables.map((item, index) =>
                              index === oilIndex ? { ...item, [type]: value } : item
                            );
                            setFieldValue('consumables', newIntervals);                      
                          }} />
                        { oil2Index && <IntervalItem interval={{...values?.consumables[oil2Index], name: "Left Oil" }} error={errors?.consumables?.[oil2Index]} index={oil2Index} handleItemUpdate={(id, type, value) => {                          
                            const newIntervals = values?.consumables.map((item, index) =>
                              index === oil2Index ? { ...item, [type]: value } : item
                            );
                            setFieldValue('consumables', newIntervals);                      
                          }} />}
                        <div className='flex mb-4'>
                          <span className='w-1/2 self-center'>Oil Added</span>
                          <FullFormikInput name="oilAdded" className="w-1/3" />
                        </div>
                      </> } </>
                    }
                    
                  </Card>
                  <Card title='Flight Attachments'>
                      <FilePicker files={files} setFiles={setFiles} />
                  </Card>
                  <div className="bg-white px-4 py-5 my-4 flex items-center justify-center shadow border border-slate-200">
                    <dt className="text-sm font-medium text-gray-500 w-1/3">Notes</dt>
                    <Field as="textarea" name="notes" className="w-2/3 border-gray-300 rounded" />
                  </div>
                  { organization?.flightBillingEnabled && !isMaintenance && <div className="bg-white px-4 py-5 my-4 flex flex-col items-center shadow border border-slate-200 item-center">
                    <div className='flex w-full justify-between' onClick={() => setFieldValue('billable', !values.billable)}>
                      <dt className="text-sm font-medium text-gray-500 w-1/3">Flight Billable</dt>
                      <FullFormikCheckBox name="billable" />
                    </div>
                    {values.billable && <div className='flex justify-end w-full my-4'>
                      <div className='w-full flex flex-col bg-brand-pale p-5 gap-10'>
                        <div className='justify-between flex w-full'>
                          <dt className="font-medium text-gray-500">Estimated Flight Charges</dt>
                          <span className="text-brand-dark">$ {totalBillable * (values.proRataShare ? passengers.length + 1 : 1)}</span>
                        </div>
                        <div className='justify-end flex w-full'>
                          <dt className="font-medium text-gray-500">{totalBillableInterval} {webFormat(billingInterval?.intervalSubType)} * {formatUSD(currentCraft?.hourlyRate)}/hr hourly rate</dt>
                        </div>
                        {values.proRataShare && <>
                          <div className='justify-between flex w-full'>
                            <dt className="font-medium text-gray-500">Pro Rata Share</dt>
                            <span className="text-brand-dark">$ {totalBillable}</span>
                          </div>
                          <div className='justify-end flex w-full'>
                            <dt className="font-medium text-gray-500">{totalBillableInterval}/hr hourly rate per person</dt>
                          </div>
                        </>
                        }
                        <div className='justify-end flex w-full'>
                          <dt className="font-medium text-gray-500">This flight will generate a transaction based on the Hourly Rate of {formatUSD(currentCraft?.hourlyRate)} multiplied by {totalBillableInterval} {webFormat(billingInterval?.intervalSubType)} hours flown {values.proRataShare ? `divided by ${(passengers.length + 1)} people` : ''}. <br></br>(Note editing a flight will not update the transaction)</dt>
                        </div>
                      </div>
                    </div>}
                  </div> }
                  {isMaintenance && <div className="bg-white px-4 py-5 my-4 flex items-center justify-center">
                    <dt className="text-sm font-medium text-gray-500 w-1/3">Maintenance Flight</dt>
                    <span className="text-sm text-gray-900 w-2/3">This flight is marked as a maintenance flight and will not be billed</span>
                  </div>}
                  <hr />
                  <div className='flex justify-end'>
                    <div className="flex flex-col items-end justify-end mt-2">
                      <Button disabled={isSubmitting || !isValid} onClick={(e) => {
                        handleSubmit(values, resetForm);
                      } } 
                        text={"Record Flight"} color="navy" type="submit" />
                      { !isValid && <span className='text-sm text-red-500 mt-1'>{'Unable to Record, Invaild Data'}</span> }
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export default New;
